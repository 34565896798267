import { createGlobalStyle } from "styled-components";
import { ZAXBYS_PIN, IC_LOCATION_PIN_WHITE_HOME } from "assets/images";
import { responsive } from "./responsive";

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    font-family: ${(props) => props.theme.font.serifRegular};
    box-sizing: border-box;
  }

  *::before, *::after {
    box-sizing: border-box;
  }

  @-ms-viewport {
    width: device-width;
  }

  .modalOpen {
    overflow: hidden;
  }

  .mobileNavOpen {
    overflow: hidden;
    header {
      z-index:99;
    }
 
    @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
      overflow: scroll;
    }
  }

  #onetrust-banner-sdk #onetrust-button-group-parent {
    @media (min-width: 437px) and (max-width: 439px){
      padding: 10px 30px 4px 29px !important;
    }
  }
  /*==============================
    MenuCategoryBoxSection Grid Style
  ==============================*/
  .HomeMenuCategoryGirdWrapper {
    background: ${(props) => props.theme.color.core.blue};
    display: grid;
    grid-auto-rows: 183px;
    grid-template-columns: repeat(4, 285px);
    @media only screen and (max-width: 1350px) {
      grid-template-columns: repeat(3, 285px);
    }
     @media only screen and (max-width: 950px) {
      grid-template-columns: repeat(2, 285px);
    } 
    @media only screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 285px);
    } 
    @media only screen and (max-width: ${(props) => props.theme.size.xs}) {
      grid-template-columns: repeat(1, 285px);
    } 
    padding: 50px 40px 48px 40px;
    justify-content: center;
    row-gap: 50.27px;
    column-gap: 20px;
    }

  /*==============================
    CUSTOMIZE GOOGLE AUTO COMPLETE
  ==============================*/
  .pac-container {
    width: 258px !important;
    background-color: ${(props) => props.theme.color.core.white};
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 48px !important; 
    /* to fix the overlay overlap with input */
    margin-top: 2px;
    padding-top: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    .pac-item {
      border-top: none;
      position: relative;
      cursor: pointer;
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-family: ${(props) => props.theme.font.barlow} !important;
      font-size: 16px;
      line-height: 19.2px;
      margin-top: 0;
      width: 100%;
      padding: 10px 16px;

      span {
        color: ${(props) => props.theme.color.primary.foundersBlue} !important;
        font-family: ${(props) => props.theme.font.barlow} !important;
        font-size: 16px;
        font-weight: normal;      
      }

      > span:last-child {
        display: block;
        margin-left: 30px;
        white-space: normal;
      }
      &:last-child {
        margin-bottom: 10px;
      } 
      
      &:hover {
        background-color: ${(props) => props.theme.color.secondary.lightBlue};
      }

      &.pac-item-selected {
        background-color: transparent;
        outline: 2px solid ${(props) => props.theme.color.secondary.coolGray4};
        outline-offset: 2px;
        
        &:hover {
        background-color: transparent;
        }
      }
      
      .pac-icon {
        background-image: url(${ZAXBYS_PIN.src});
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 0;
        position: absolute;   
        bottom: 20px;    
        width: 20px;
      }
      
      .pac-matched {
        font-weight: 800;
      }

      .pac-item-query{
        margin-left: 30px;
      }
    }

    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      /* 74px for the gps and filter icon + 24px * 2 for the padding + 1px as per figma */
      width: calc(100% - 123px) !important;
      left: 25px !important;
      .pac-item {
        padding: 5px 20px;
        font-size: 14px;
        span {  
        font-size: 14px;
        font-weight: 400;      
      }
      }
    } 
  }
  .pac-logo {
    &::after {
      display: none;
    }
  }

  /*=======================================
    GOOGLE AUTO COMPLETE FOR ADDRESS MODALS
  =======================================*/
  .address-screen, .addressAutoComplete {
    .pac-container {
      left: calc(50% - 7px) !important;
      transform: translateX(-50%);
      width: 424px !important;
      background-color: ${(props) => props.theme.color.core.blue};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .pac-item {
        padding: 0 32px;
        color: ${(props) => props.theme.color.core.white};

        &:hover, &:focus {
          background-color: ${(props) => props.theme.color.core.blue};
        }

        .pac-matched, .pac-item-query {
          color: ${(props) => props.theme.color.core.white};
        }

        .pac-icon {
          background-image: url(${IC_LOCATION_PIN_WHITE_HOME.src});
        }
      }

      @media only screen and (max-width: ${(props) => props.theme.size.md}) {
        left: 50% !important;
      }

      @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
        width: 305px !important;
        left: 50% !important;
      }
    }
  }
  .passwordMask {
    -webkit-text-security: disc;
  }
  .address-screen {
    .pac-container {
      left: 50% !important;
    }
  }

  ${responsive.max.lg(`
    .driver-notes-heading {
      margin-bottom: 13px;
    }
    .address-screen {
      header {
        display: none;
      }
      .violatorBar {
        display: none;
      }
    }
    .modalOpen {
      .violatorBar {
        display: none;
      }
    }

    .checkoutModals {
      .headerTop {
        position: fixed !important;
        width: 100%;
        z-index: 10000 !important;
        .checkoutModalOverlay {
          top: 91px;
        }
        .hideStoreBarLocation {
          &.pickupLocation {
            display: flex !important;
          }
        }
      }
    }
  `)}

  /*=======================================
   Checkout Container Spacing
  =======================================*/

  .containerSpacing {
    ${(props) => props.theme.responsive.max.lg} {
      padding: 0 30px !important;
    }
  }
  /* Removed the report-a-problem button from map */
  .gm-style-cc:last-child {
    display: none !important;
  }

  .no-scroll {
    overflow: hidden;
  }
`;
