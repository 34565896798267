import router from "next/router";
import Image from "next/image";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "hooks/useWindowDimensions";
import { CommonLayerResponseData, RootState } from "src/types/types";
import { IC_CHECKMARK_GREEN } from "assets/images";
import { getPhoneNumberInStarformat } from "utils/formatter/getPhoneNumberInDashFormat";
import { formattedCMSErrorMessage, formattedCMSHelpUsText } from "utils/formattingCMSMessage";
import { handleUserLogout } from "utils/helper";
import { getCMSUserAccountProfile } from "api/users/get-user-account-profile";
import { setUserProfilePhoneUpdateData, setIsOldPhoneNumValidated } from "redux/reducer/UserStatus/UserStatusAction";
import Button from "components/atomic-components/atoms/button/Button";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import ErrorModal from "components/atomic-components/molecules/ErrorModal/ErrorModal";
import { ButtonWrapper } from "containers/Account/Profile/UpdatePassword/UpdatePasswordStyled";
import { CodeDeliveryMedium, CodeDeliveryOptions } from "containers/ForgotPassword/types";
import CodeInput from "../Inputs/CodeInput/CodeInput";
import StackedButtonsContainer from "../StackedButtonsContainer/StackedButtonsContainer";
import { GenericSuccessMessage } from "../GenericGreenMessage/GenericSuccessMessage";
import BackButton from "../Buttons/BackButton/BackButton";
import { useEnterCode } from "./useEnterCode";
import { getStringBasedOnPage } from "./EnterCodeHelper";
import { EnterCodeStyled, OTPErrorMessage, PhoneOTPValidationError } from "./EnterCodeStyled";
import { renderMessageBasedOnMedium } from "./RenderMessage";

interface Props {
  target: string;
  codeLength?: number;
  onResend: () => Promise<any>;
  onSubmit: (code: string) => Promise<any>;
  onChange?: (value: string) => void;
  isBackButtonPresent?: boolean;
  onBackBtnClick?: () => void;
  className?: string;
  isProfilePhonePage?: boolean;
  initialCode?: string;
  hidePhoneNumber?: boolean;
  medium?: CodeDeliveryMedium;
  options?: CodeDeliveryOptions;
}

const Confirmed = () => {
  return (
    <div className="phone-verified">
      <Paragraph variant="paragraphLogistics1" fontWeight={800} mobileFontWeight={700} mobileFontSize={16}>
        CONFIRMED &nbsp;
      </Paragraph>
      <span>
        <Image alt="verified symbol" src={IC_CHECKMARK_GREEN} />
      </span>
    </div>
  );
};

const EnterCode = ({
  target,
  className,
  codeLength = 6,
  onResend,
  onSubmit,
  onChange,
  onBackBtnClick,
  isProfilePhonePage = false,
  isBackButtonPresent,
  initialCode,
  hidePhoneNumber = false,
  medium,
  options,
}: Props) => {
  const {
    code,
    error,
    errorMessage,
    handleChange,
    handleResend,
    handleSubmit,
    complete,
    isLoading,
    apiErrorMessage,
    isResendCodeSelected,
  } = useEnterCode(codeLength, onResend, onSubmit, isProfilePhonePage, initialCode);

  const {
    code: codeNewNumber,
    error: errorNewNumber,
    handleChange: handleNewNumberChange,
    handleResend: handleNewNumberResend,
    handleSubmit: handleNewNumberSubmit,
    complete: completeNewNumber,
    isLoading: isNewNumberLoading,
    apiErrorMessage: apiErrorNewNumberMessage,
    isResendCodeSelected: isNewNumberResendCodeSelected,
  } = useEnterCode(codeLength, onResend, onSubmit, isProfilePhonePage);

  const { details, isOldPhoneNumberValidated, userProfilePhoneUpdate, errorMessageFlag } = useSelector((state: RootState) => ({
    details: state.user.details,
    isOldPhoneNumberValidated: state.userStatus.isOldPhoneNumberValidated,
    userProfilePhoneUpdate: state.userStatus.userProfilePhoneUpdate,
    errorMessageFlag: state.userStatus?.errorMessageFlag,
  }));
  const dispatch = useDispatch();

  const isMobile = useWindowDimensions().width < 768;

  useEffect(() => {
    if (!isProfilePhonePage) {
      dispatch(setIsOldPhoneNumValidated(false));
    }
    getCMSUserAccountProfile().then((res: CommonLayerResponseData) => dispatch(setUserProfilePhoneUpdateData(res.response)));
  }, []);

  const { Title, Body, NewPhoneNumMessage, HelpMessage, HelpMessageLinkText, HelpMessageLink, HelpMessageKey } =
    userProfilePhoneUpdate?.MFAPhoneUpdate?.UpdatePhoneModel ?? "";

  return (
    <EnterCodeStyled
      className={className}
      isError={error || errorNewNumber}
      isShowCMSErrorMessage={Boolean(errorMessageFlag && (apiErrorMessage?.ErrorMessage || apiErrorNewNumberMessage?.ErrorMessage))}
    >
      {isBackButtonPresent && (
        <BackButton type="button" variant="twelveth" onClick={onBackBtnClick} className="enterYourCodeBackButton">
          Back
        </BackButton>
      )}

      <Headings className="heading" fontSize={40} fontWeight={800} mobileFontSize={28} variant={"secondaryHeading2"}>
        {Title ?? "ENTER YOUR CODE"}
      </Headings>
      {isMobile && <hr className="horizontalDivider" />}
      {isProfilePhonePage ? (
        <>
          <Paragraph variant="primaryParagraph1" fontSize={14} mobileFontWeight={500} mobileFontSize={16}>
            {Body}
          </Paragraph>
          <Paragraph variant="paragraphHeading1">{getPhoneNumberInStarformat(details?.phone)}</Paragraph>
        </>
      ) : (
        renderMessageBasedOnMedium({ target, codeLength, hidePhoneNumber, medium, options, isMobile })
      )}
      <CodeInput
        name="code"
        value={code}
        error={error}
        placeholderText="••••••"
        length={codeLength}
        onChange={(name, value) => {
          handleChange(name, value);
          onChange?.(value);
        }}
        isDisabled={isOldPhoneNumberValidated}
      />
      {isResendCodeSelected && <GenericSuccessMessage>NEW CODE SENT</GenericSuccessMessage>}
      {Object.keys(apiErrorMessage ?? {}).length > 0 ? (
        <div className="">
          <PhoneOTPValidationError
            dangerouslySetInnerHTML={{ __html: formattedCMSErrorMessage(apiErrorMessage, true) }}
          ></PhoneOTPValidationError>
        </div>
      ) : (
        <OTPErrorMessage>{error && errorMessage}</OTPErrorMessage>
      )}

      <StackedButtonsContainer direction="col">
        {isOldPhoneNumberValidated ? (
          <Confirmed />
        ) : (
          <ButtonWrapper>
            <Button
              variant="primaryBlue"
              data-testid="confirmButton"
              onClick={() => handleSubmit(target)}
              className={code.length === 6 && !isLoading && !isOldPhoneNumberValidated && !error ? "" : "disabled"}
            >
              VERIFY ME
            </Button>

            <Button
              variant="ghostBlue"
              underline
              type="button"
              className={getStringBasedOnPage(isProfilePhonePage, "resendProfilePageOTP", "resendForgotPasswordCode")}
              onClick={handleResend}
              disabled={!complete}
            >
              RESEND CODE
            </Button>
          </ButtonWrapper>
        )}
      </StackedButtonsContainer>

      {isProfilePhonePage && isOldPhoneNumberValidated && (
        <>
          <Paragraph variant="primaryParagraph1" fontSize={14} mobileFontWeight={500} mobileFontSize={16}>
            {NewPhoneNumMessage}
          </Paragraph>
          <Paragraph variant="paragraphHeading1">{getPhoneNumberInStarformat(target)}</Paragraph>
          <CodeInput name="code" value={codeNewNumber} error={errorNewNumber} length={codeLength} onChange={handleNewNumberChange} />
          {isNewNumberResendCodeSelected && <GenericSuccessMessage>NEW CODE SENT</GenericSuccessMessage>}

          {Object.keys(apiErrorNewNumberMessage ?? {}).length > 0 && (
            <PhoneOTPValidationError
              dangerouslySetInnerHTML={{ __html: formattedCMSErrorMessage(apiErrorNewNumberMessage, true) ?? "" }}
            ></PhoneOTPValidationError>
          )}

          <div className="buttonContainer">
            <Button
              variant="primaryBlue"
              onClick={() => handleNewNumberSubmit(target)}
              className={codeNewNumber.length === 6 && !isNewNumberLoading && !errorNewNumber ? "" : "disabled"}
            >
              VERIFY ME
            </Button>
            <Button
              variant="ghostBlue"
              underline
              className="resendProfilePageOTP"
              onClick={handleNewNumberResend}
              disabled={!completeNewNumber}
            >
              RESEND CODE
            </Button>
          </div>
        </>
      )}
      {isProfilePhonePage && (
        <div
          className="callUsText"
          dangerouslySetInnerHTML={{
            __html: formattedCMSHelpUsText(HelpMessage, HelpMessageLink, HelpMessageLinkText, HelpMessageKey) ?? "",
          }}
        />
      )}
      {errorMessageFlag && (apiErrorMessage?.ErrorMessage || apiErrorNewNumberMessage?.ErrorMessage) && (
        <ErrorModal
          errorModalClassName="errorModal"
          showHorizontalLine
          heading="You’ve been logged out"
          subHeading={
            apiErrorMessage?.ErrorMessage
              ? formattedCMSErrorMessage(apiErrorMessage)
              : formattedCMSErrorMessage(apiErrorNewNumberMessage) ?? ""
          }
          mobileVariant="fullScreen"
          isBackButton={false}
          hideButtonCTA
          isShowCMSErrorMessage={true}
          showCloseButton
          onClose={() => {
            handleUserLogout();
            router.push("/account/logged-out");
          }}
        />
      )}
    </EnterCodeStyled>
  );
};
export default EnterCode;
