import React, { useEffect } from "react";
import Link from "components/atomic-components/atoms/typography/Links/Links";
import DropdownNote from "components/atomic-components/atoms/Dropdowns/DropdownNote/DropdownNote";
import EnterCode from "components/global/EnterCode/EnterCode";
import { sendPasswordVerificationCode } from "api/users/send-password-verification-code";
import { checkPasswordVerificationCode } from "api/users/check-password-verification-code";
import { getLocalStorageData } from "utils/storage";
import Analytics from "analytics/Analytics";
import { ANALYTICS_CONST, PAGE_NAME } from "constants/analytics";
import ForgotPasswordContext from "../ForgotPasswordContext";
import { ForgotPasswordEnterCodeStyled } from "./ForgotPasswordEnterCodeStyled";

const ForgotPasswordEnterCode = () => {
  const { username, options, medium, setStep, setCode } = React.useContext(ForgotPasswordContext);

  const logPageViewEvent = async () => {
    const isUserMigrated = getLocalStorageData("isUserMigrated");

    if (isUserMigrated === "Yes") {
      Analytics.getInstance().logEvent("page_view", {
        page_name: PAGE_NAME.MIGRATION_ENTER_YOUR_CODE,
      });
    }
  };

  useEffect(() => {
    logPageViewEvent();
  }, []);

  const getTarget = () => {
    switch (medium) {
      case "BOTH":
        return options.email + " and " + options.phone;

      case "EMAIL":
        return options.email;

      case "PHONE":
        return options.phone;

      default:
        return "";
    }
  };

  const handleResend = async () => {
    return sendPasswordVerificationCode({
      username,
      codeDeliveryMedium: medium,
    });
  };

  const handleSubmit = async (code: string) => {
    const isUserMigrated = getLocalStorageData("isUserMigrated");

    return checkPasswordVerificationCode({ username, code })
      .then((response) => {
        if (response.success) {
          setStep("CONFIRM");
          setCode(code);

          if (isUserMigrated === "Yes") {
            Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.MIGRATION_PHONE_VERIFICATION_SUCCESS);
          }
        } else {
          if (isUserMigrated === "Yes") {
            Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.MIGRATION_PHONE_VERIFICATION_ERROR);
          }
        }
        return response;
      })
      .catch(() => {
        if (isUserMigrated === "Yes") {
          Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.MIGRATION_PHONE_VERIFICATION_ERROR);
        }
      });
  };

  const goToPreviousPage = () => (options.status === "UNCONFIRMED" ? setStep("PROMPT") : setStep("SEND_CODE"));

  return (
    <ForgotPasswordEnterCodeStyled>
      <EnterCode
        medium={medium}
        options={options}
        codeLength={6}
        target={getTarget()}
        onResend={handleResend}
        onSubmit={handleSubmit}
        onBackBtnClick={goToPreviousPage}
        isBackButtonPresent
        hidePhoneNumber
      />
      <DropdownNote
        title="NEVER GOT A CODE?"
        bulletPoints={[
          <>Try using a different email address</>,
          <>Create a new account</>,
          <>
            Call our Guest Support Team at{" "}
            <Link to="tel:18668929297" containerClassName="linkStyles">
              1-866-892-9297
            </Link>
          </>,
        ]}
      />
    </ForgotPasswordEnterCodeStyled>
  );
};

export default ForgotPasswordEnterCode;
