import { Dispatch } from "redux";
import { GetStartedMessage, SmsGetStartedMessage } from "src/types/types";
import { getFranchiseFooterCMSData } from "api/cms/franchise/franchise-footer.ts/footer";
import { getFranchiseHomeCMSData } from "api/cms/franchise/homepage/get-franchise-homepage-cms-data";
import { getFranchiseAboutUsCMSData } from "api/cms/franchise/aboutus/get-franchise-aboutus";
import { getFranchiseGetStartedCMSData } from "api/cms/franchise/getstarted/franchise-get-started";
import { getFranchiseFAQCMSData } from "api/cms/franchise/faq/franchsise-faq";
import { getWhyZaxbysCMSData } from "api/cms/franchise/whyzaxbys/why-zaxbys";
import { getInvestmentCMSData } from "api/cms/franchise/investment/investment";
import { getFranchiseProcessCMSData } from "api/cms/franchise/process/franchise-process";
import { getFranchiseLocationsCMSData } from "api/cms/franchise/locations/franchise-locations";
import { getFranchiseAwardsCMSData } from "api/cms/franchise/awards/franchise-awards";
import { getFranchiseLeadershipCMSData } from "api/cms/franchise/leadership/franchise-leadership";
import { getFranchiseTestimonialCMSData } from "api/cms/franchise/testimonials/franchise-testimonials";
import { getFranchiseProfileCMSData } from "api/cms/franchise/profile/franchise-profile";
import { getFranchiseTrainingCMSData } from "api/cms/franchise/training/franchise-training";
import { getFranchiseBlogsCMSData } from "api/cms/franchise/blogs/franchise-blogs";
import {
  SET_FRANCHISE_ABOUT_US,
  SET_FRANCHISE_AWARDS,
  SET_FRANCHISE_FAQ,
  SET_FRANCHISE_FOOTER_DATA,
  SET_FRANCHISE_GET_STARTED,
  SET_FRANCHISE_GET_STARTED_MESSAGE,
  SET_FRANCHISE_SMS_GET_STARTED_MESSAGE,
  SET_FRANCHISE_HOME_DATA,
  SET_FRANCHISE_INVESTMENT,
  SET_FRANCHISE_LOCATIONS,
  SET_FRANCHISE_PROCESS,
  SET_WHY_ZAXBYS,
  SET_FRANCHISE_LEADERSHIP,
  SET_FRANCHISE_TESTIMONIALS,
  SET_FRANCHISE_PROFILE,
  SET_FRANCHISE_TRAINING,
  SET_FRANCHISE_BLOGS,
  SET_FRANCHISE_GET_STARTED_FORM_LOADING,
  SET_FRANCHISE_BLOGS_URLS,
  SET_FRANCHISE_BLOGS_PAGINATION_PAGE,
} from "./FranchiseConst";
import { Action } from "./types";

export const setFranchiseGetStartedMessage = (payload: GetStartedMessage) => {
  return { type: SET_FRANCHISE_GET_STARTED_MESSAGE, payload: payload || {} };
};

export const setFranchiseSMSGetStartedMessage = (payload: SmsGetStartedMessage) => {
  return { type: SET_FRANCHISE_SMS_GET_STARTED_MESSAGE, payload: payload || {} };
};

export const setFranchiseFooterCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseFooterCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_FOOTER_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_FOOTER_DATA, payload: null }));
};

export const setFranchiseHomePageCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseHomeCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_HOME_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_HOME_DATA, payload: null }));
};

export const setFranchiseAboutUsCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseAboutUsCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_ABOUT_US, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_ABOUT_US, payload: null }));
};

export const setFranchiseGetStartedCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseGetStartedCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_GET_STARTED, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_GET_STARTED, payload: null }));
};

export const setFranchiseFAQPageCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseFAQCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_FAQ, payload: res.response as any }))
    .catch(() => dispatch({ type: SET_FRANCHISE_FAQ, payload: null }));
};

export const setWhyZaxbysPageCMSData = () => (dispatch: Dispatch<Action>) => {
  getWhyZaxbysCMSData()
    .then((res) => dispatch({ type: SET_WHY_ZAXBYS, payload: res.response }))
    .catch(() => dispatch({ type: SET_WHY_ZAXBYS, payload: null }));
};

export const setInvestmentPageCMSData = () => (dispatch: Dispatch<Action>) => {
  getInvestmentCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_INVESTMENT, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_INVESTMENT, payload: null }));
};

export const setFranchiseProcessPageCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseProcessCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_PROCESS, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_PROCESS, payload: null }));
};

export const setFranchiseLocationCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseLocationsCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_LOCATIONS, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_LOCATIONS, payload: null }));
};

export const setFranchiseAwardsCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseAwardsCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_AWARDS, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_AWARDS, payload: null }));
};

export const setFranchiseLeadershipCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseLeadershipCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_LEADERSHIP, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_LEADERSHIP, payload: null }));
};

export const setFranchiseTestimonialCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseTestimonialCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_TESTIMONIALS, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_TESTIMONIALS, payload: null }));
};

export const setFranchiseProfileCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseProfileCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_PROFILE, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_PROFILE, payload: null }));
};

export const setFranchiseTrainingCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseTrainingCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_TRAINING, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_TRAINING, payload: null }));
};

export const setFranchiseBlogsCMSData = () => (dispatch: Dispatch<Action>) => {
  getFranchiseBlogsCMSData()
    .then((res) => dispatch({ type: SET_FRANCHISE_BLOGS, payload: res.response }))
    .catch(() => dispatch({ type: SET_FRANCHISE_BLOGS, payload: null }));
};

export const setFranchiseFormLoader = (payload: boolean) => {
  return { type: SET_FRANCHISE_GET_STARTED_FORM_LOADING, payload: payload };
};

export const setBlogsURLData = (payload: { URL: string }[]) => {
  return { type: SET_FRANCHISE_BLOGS_URLS, payload: payload };
};

export const setFranchiseBlogsPaginationPage = (payload: number) => {
  return { type: SET_FRANCHISE_BLOGS_PAGINATION_PAGE, payload: payload };
};
