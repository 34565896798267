import React, { ReactNode } from "react";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { CodeDeliveryMedium } from "containers/ForgotPassword/types";

interface RenderMessageProps {
  target: string;
  codeLength: number;
  hidePhoneNumber: boolean;
  medium?: CodeDeliveryMedium;
  options?: {
    phone?: string;
    email?: string;
  };
  isMobile: boolean;
}

export const renderMessageBasedOnMedium = ({
  target,
  codeLength,
  hidePhoneNumber,
  medium,
  options,
  isMobile,
}: RenderMessageProps): ReactNode => {
  // Helpers
  const getCodeMessage = (contactInfo: string) => (
    <>
      <Paragraph variant="secondaryParagraph1" className="enterCodeText">
        We sent your {codeLength}-digit code to:
      </Paragraph>
      <Paragraph variant="paragraphHeading1" className="contactInfo">
        {contactInfo}
      </Paragraph>
    </>
  );

  const getDefaultText = () => getCodeMessage(target);

  // Old flow
  if (!hidePhoneNumber || !medium || !options?.email || !options?.phone) {
    return getDefaultText();
  }

  // Hidden phone number flow
  switch (medium) {
    case "PHONE":
      return (
        <Paragraph variant="secondaryParagraph1" className="enterCodeText">
          We&apos;ve sent your {codeLength}-digit code to your phone number on file.
        </Paragraph>
      );

    case "EMAIL":
      return getCodeMessage(target);

    // For Signup MFA New Flow
    case "SMS":
      return (
        <>
          <Paragraph variant="secondaryParagraph1" className="enterCodeText">
            We sent your {codeLength}-digit code to:
          </Paragraph>
          <Paragraph variant="paragraphHeading1" className="contactInfo">
            {options?.phone}
          </Paragraph>
        </>
      );

    case "BOTH":
      return (
        <>
          <Paragraph variant="secondaryParagraph1" className="enterCodeText">
            We sent your {codeLength}-digit code to:
          </Paragraph>
          <Paragraph variant="paragraphHeading1" className="contactInfo">
            {options.email}
            {isMobile && <span className="andSeparator"> and</span>}
          </Paragraph>
          <Paragraph variant="secondaryParagraph1" className="phoneFileTxt">
            {isMobile ? "" : "and"} to your phone number on file.
          </Paragraph>
        </>
      );

    default:
      return getDefaultText();
  }
};
