import { useState } from "react";
import { isMobile } from "../api/utils";
import { GenericObject } from "../types/types";
import { phoneNumberFormat } from "../utils/index";

interface Params<Values, Data> {
  initial: Values;
  validate: (name: keyof Values, value: any) => string;
  onSubmit: (values: Values, callback?: Function) => Promise<Data>;
  validationSchema?: any;
  validateFormOnLoad?: boolean;
  skipValidation?: Array<string>;
}

export const useForm = <Values extends GenericObject, Data>({
  initial,
  validate,
  onSubmit,
  validationSchema = null,
  validateFormOnLoad,
  skipValidation,
}: Params<Values, Data>) => {
  // eslint-disable-next-line no-unused-vars
  type Errors = { [key in keyof Values]: string };

  const getInitialErrors = () => {
    const initialErrors: Errors = {} as Errors;
    for (const key in initial) {
      initialErrors[key] = "";
    }

    return initialErrors;
  };

  const handleValidate = () => {
    const validationErrors: Errors = {} as Errors;

    for (const key in initial) {
      // Skip validation for the particular field
      if (skipValidation?.includes(key)) {
        continue;
      }
      validationErrors[key] = validateField(key, values[key]);
    }

    return validationErrors;
  };

  const [values, setValues] = useState(initial);
  const [errors, setErrors] = useState(getInitialErrors());
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name: keyof Values, value: any) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    if (isMobile()) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: value === false ? validateField(name, value) : "" }));
    }
  };

  const handleBlur = (name: keyof Values, value: any) => {
    if (name === "phone") {
      const updatedPhoneNumber = phoneNumberFormat(value);
      setValues((prev) => ({ ...prev, phone: updatedPhoneNumber }));
    }
    if (typeof name === "string") {
      setErrors((prev) => ({ ...prev, [name]: validateField(name, value) || "" }));
    }
  };

  const validateField = (name: keyof Values, value: any) => {
    if (validationSchema?.[name]) {
      try {
        validationSchema[name].validateSync({ [name]: value });
      } catch (err: any) {
        return err?.errors.length > 0 ? err.errors[0] : "";
      }
    } else {
      return validate(name, value);
    }
  };

  const handleSubmit = async (callback?: Function) => {
    const errorMessages = handleValidate();
    const errorExistsLocal = Object.values(errorMessages).some((x) => x);

    if (errorExistsLocal) {
      setErrors(errorMessages);
      throw Error("Validation Error");
    }
    setIsLoading(true);
    const res = await onSubmit(values, callback);
    setIsLoading(false);
    return res;
  };

  const getInputProps = <T>(name: keyof Values) => ({
    name,
    value: values[name] as T,
    error: errors[name] as string,
    onChange: handleChange as (name: string, value: T) => void,
    onBlur: handleBlur as (name: string, value: T) => void,
  });

  const hasChanged = JSON.stringify(initial) !== JSON.stringify(values);

  // Validate the error on form load
  let errorExists = false;
  if (validateFormOnLoad) {
    for (const key in initial) {
      // Skip validation for the particular field
      if (skipValidation?.includes(key)) {
        continue;
      }

      // Validate only required fields and their values
      if (validationSchema?.[key] && (values[key] === "" || !values[key])) {
        errorExists = true;
        break;
      }
    }
  }

  if (errorExists === false) {
    errorExists = Object.values(errors).some((x) => x);
  }

  return {
    values,
    errors,
    isLoading,
    hasChanged,
    errorExists,
    handleValidate,
    handleBlur,
    handleChange,
    handleSubmit,
    getInputProps,
  };
};
