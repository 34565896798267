import { apiCall } from "src/apis/api";
import { USERS } from "../endpoints";

interface Request {
  email: string;
  phone: string;
  shouldFinish: boolean;
}

export const deleteUser = (data: Request) => {
  return apiCall({
    apiEndPoint: `${USERS}`,
    requestType: "PATCH",
    apiPostData: {
      ...data,
    },
  });
};
