import { useEffect, useState } from "react";
import { useDebounce } from "hooks/useDebounce";

const MFA_IDLE_TIME_IN_MINS = Number(process.env.NEXT_PUBLIC_MFA_IDLE_TIMEOUT_IN_MINS ?? 30);

const ONE_MIN_IN_MILLISECONDS = 60 * 1000;
const THIRTY_MINS_IN_MILLISECONDS = MFA_IDLE_TIME_IN_MINS * ONE_MIN_IN_MILLISECONDS;

export const useCheckIsPageIdleForThirtyMins = () => {
  const [lastActionTime, setLastActionTime] = useState(0);
  const [isPageIdleForThirtyMins, setIsPageIdleForThirtyMins] = useState(false);
  const debouncedLastActionTime = useDebounce(lastActionTime, 1000);

  useEffect(() => {
    let timeoutId: any = null;
    timeoutId = setTimeout(() => {
      setIsPageIdleForThirtyMins(true);
    }, THIRTY_MINS_IN_MILLISECONDS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [debouncedLastActionTime]);

  return { isPageIdleForThirtyMins, setLastActionTime, setIsPageIdleForThirtyMins };
};
