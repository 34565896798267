import styled from "styled-components";
import { responsive } from "theme/responsive";

export const SendCodeStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    margin-top: 30px;
    margin-bottom: 18px;
    text-transform: uppercase;
  }
  .horizontalDivider {
    border-bottom: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
    width: 294px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .text {
    margin-bottom: 33px;
    text-align: center;
  }

  .checkBox {
    min-width: 220px;
    margin-bottom: 10px;
    &.textACode {
      @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
        margin-bottom: 20px;
      }
      display: flex;
    }
    .input-checkbox {
      padding-left: 12px;
    }
  }
  .cardBody {
    font-size: 16px;
  }
  .cancelButton {
    width: 180px;
    height: 44px !important;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      width: 200px;
      height: 36px !important;
    }
  }

  .sendButton {
    width: 180px;
    height: 44px !important;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      width: 200px;
      height: 36px !important;
    }
  }

  .stackedButtons {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      display: flex;
      flex-direction: column;
    }
  }

  .sendACodeBackButton {
    position: absolute;
    left: 29px;
    top: 30px;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 20px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    .stackedButtons {
      width: auto;
      margin-top: 30px;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .heading {
      margin-bottom: 10px;
    }
    .sendACodeBackButton {
      top: 20px;
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 15px;
    }
    .text {
      font-weight: 500;
      font-size: 16px;
      width: 220px;
    }
  }
`;

export const CheckboxText = styled.span`
  .checkbox-label {
    font-size: 16px;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    margin: 0 2px;
    ${responsive.max.md(`
    font-size: 14px;

`)}
  }
`;

export const CheckboxBoldText = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  font-family: ${(props) => props.theme.font.barlow} !important;
  color: inherit;
  margin: 0 2px;
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    font-size: 14px;
  }
`;
