import styled from "styled-components";

export const PromptModalStyled = styled.div`
  display: flex;
  align-items: center;
  .modalClose {
    position: absolute;
    top: 20px;
  }
  .modal {
    overflow-y: auto;
  }
  .stackedButton {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
  }
  .pickupButton,
  .deliveryButton {
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 20px;
    font-weight: 700;
  }
  .authCardClose {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    top: 28px;
    right: 28px;
    padding: 0;
  }
  .marginBottom {
    margin-bottom: 16px;
  }
  .title {
    margin-top: 50px;
    font-size: 40px;
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    font-weight: 800;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    text-transform: uppercase;
  }
  .locationString {
    font-family: ${(props) => props.theme.font.ZaxCasual};
    font-style: italic;
    font-weight: 700;
    font-size: 34px;
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    text-transform: uppercase;
  }
  .subHeading {
    margin: 0 70px;
    padding-top: 24px;
    text-align: center;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.core.black};
    font-weight: 400;
    font-size: 18px;
    line-height: 21.6px;
  }
  .selectStoreModalContainer {
    overflow: hidden;
    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.color.primary.coleslawWhite} 90%,
      ${(props) => props.theme.color.primary.foundersBlue}
    );
  }

  .cardBody {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding: 30px 30px 36px 30px;
  }
  .cardFooter {
    background: ${(props) => props.theme.color.primary.foundersBlue};
    border-top-right-radius: 50% 30px;
    border-top-left-radius: 50% 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .cardBody {
      padding: 24px 24px 24px 24px;
    }
    .modal {
      height: auto;
      border-radius: 20px;
      width: 70%;
      margin-top: 23%;
    }
    .authCardClose {
      top: 24px;
      right: 24px;
    }

    .title {
      margin-top: 10px;
      font-size: 24px;
      line-height: 29px;
    }
    .locationString {
      font-size: 21px;
    }

    .subHeading {
      margin: 0;
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 500;
    }

    .stackedButton {
      flex-direction: column;
      align-items: center;
    }
    .pickupButton,
    .deliveryButton {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 15px;
      padding: 8px 0;
    }
    .cardFooter {
      background: ${(props) => props.theme.color.primary.foundersBlue};
      padding: 30px 50px;
      width: 106%;
      transform: translate(-6px, 0px);
    }
  }
`;
