import styled from "styled-components";

export const CancelAccountCreationPopupStyled = styled.div`
  display: flex;

  .modal {
    border-radius: 20px;
  }

  .cardBody {
    padding: 72px 0 36px;
  }

  .cardFooter {
    padding: 60px 30px 40px;
    width: 106%;
    transform: translate(-12px, 0);
  }

  ${(props) => props.theme.responsive?.max.md} {
    .d-none {
      display: none;
    }
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .cardBody {
      padding: 40px 24px 36px;
    }

    .cardFooter {
      padding: 36px 20px 32px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  > button {
    width: 210px;
    height: 44px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    flex-direction: column;

    > button {
      width: 176px;
      height: 36px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .cancelAccountTitle {
    display: flex;
    text-align: center;
  }

  .cancelAccountSubtitle {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 24px 120px 0;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .cancelAccountSubtitle {
      flex-direction: row;
      margin: 24px 0 0;
    }
  }
`;
