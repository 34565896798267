import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "hooks/useForm";
import { getErrorUsingType } from "utils/validation";
import { setSignupDetails } from "redux/reducer/auth/signup/actions";
import { apiCall } from "src/apis/api";
import { USERS } from "src/api/endpoints";
import {
  NAME_INVALID,
  EMAIL_INVALID,
  PHONE_INVALID,
  PASSWORD_INVALID,
  REQUIRED_FIELD,
  REFERRAL_CODE_INVALID,
} from "constants/errorMessages";
import { NAME_REGEX, PASSWORD_REGEX, PHONE_FORMAT_REGEX, REFERRAL_CODE_REGEX } from "constants/regexPatterns";
import { SignUpRequest } from "src/apis/types";
import { RootState } from "types/types";

export const useSignup = () => {
  const dispatch = useDispatch();
  const { signupDetails } = useSelector((state: RootState) => ({
    signupDetails: state.signup,
  }));
  const signUpSchema = {
    firstName: yup.object().shape({
      firstName: yup.string().required(REQUIRED_FIELD).matches(NAME_REGEX, NAME_INVALID),
    }),
    lastName: yup.object().shape({
      lastName: yup.string().required(REQUIRED_FIELD).matches(NAME_REGEX, NAME_INVALID),
    }),
    phone: yup.object().shape({
      phone: yup.string().required(REQUIRED_FIELD).matches(PHONE_FORMAT_REGEX, PHONE_INVALID),
    }),
    email: yup.object().shape({
      email: yup.string().email(EMAIL_INVALID).required(REQUIRED_FIELD),
    }),
    password: yup.object().shape({
      password: yup.string().required(REQUIRED_FIELD).matches(PASSWORD_REGEX, PASSWORD_INVALID),
    }),
    termsAndConditions: yup.object().shape({
      termsAndConditions: yup.bool().oneOf([true], REQUIRED_FIELD),
    }),
    referralCode: yup.object().shape({
      referralCode: yup.string().required(REQUIRED_FIELD).matches(REFERRAL_CODE_REGEX, REFERRAL_CODE_INVALID),
    }),
  };

  return {
    ...useForm({
      initial: {
        firstName: signupDetails?.firstName ?? "",
        lastName: signupDetails?.lastName ?? "",
        phone: "",
        email: signupDetails?.email ?? "",
        password: "",
        textCommunication: false,
        emailCommunication: true,
        termsAndConditions: true,
        hasCode: signupDetails?.hasCode ?? false,
        referralCode: signupDetails?.referralCode ?? "",
      },
      skipValidation: ["referralCode", "termsAndConditions"],
      validateFormOnLoad: true,
      validationSchema: signUpSchema,
      validate: (name, value) => {
        return {
          firstName: () => getErrorUsingType("NAME", value as string),
          lastName: () => getErrorUsingType("NAME", value as string),
          phone: () => getErrorUsingType("PHONE", value as string),
          email: () => getErrorUsingType("EMAIL", value as string),
          password: () => getErrorUsingType("PASSWORD", value as string),
          termsAndConditions: () => "",
          textCommunication: () => "",
          emailCommunication: () => "",
          hasCode: () => "",
          referralCode: () => getErrorUsingType("REFERRALCODE", value as string),
        }[name]();
      },

      onSubmit: async (values) => {
        const formData: SignUpRequest = {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          email: values.email,
          password: values.password,
          phone: values.phone.replace(/[() -]/g, ""),
          mfa: false,
          subscription: {
            sms: values.textCommunication,
            email: values.emailCommunication,
          },
          referralCode: values.referralCode,
        };

        const apiDetails = {
          requestType: "POST",
          apiEndPoint: `${USERS}/signup`,
          apiPostData: formData,
        };

        return apiCall(apiDetails).then((res) => {
          if (res.success) {
            dispatch(
              setSignupDetails({
                username: values.email,
                codeDeliveryDetails: res.response.codeDeliveryDetails || null,
                ...values,
              })
            );
          }
          return res;
        });
      },
    }),
  };
};
