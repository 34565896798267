import { apiCall } from "src/apis/api";
import { SEND_PASSWORD_VERIFICATION_CODE } from "../endpoints";

interface Request {
  username: string;
  codeDeliveryMedium: "PHONE" | "EMAIL" | "BOTH" | "SMS";
}

export const sendPasswordVerificationCode = async (data: Request) => {
  return apiCall({
    apiEndPoint: SEND_PASSWORD_VERIFICATION_CODE,
    requestType: "POST",
    apiPostData: {
      username: data.username,
      codeDeliveryMedium: data.codeDeliveryMedium,
    },
  });
};
