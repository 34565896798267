import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/global/Modal/Modal";
import EnterCode from "components/global/EnterCode/EnterCode";
import SignInSignUpCard from "components/Auth/SignInSignUpCard/SignInSignUpCard";
import useModal from "hooks/useModal";
import { useVerify } from "hooks/auth/signup/useVerify";
import { useMParticle } from "hooks/useMParticle";
import { useCheckIsPageIdleForThirtyMins } from "hooks/useCheckIsIdleForThirtyMins";
import { setSessionStorageData } from "utils/storage";
import { RootState } from "src/types/types";
import { deleteUser } from "api/users/delete-user";
import { setSignupDetails } from "redux/reducer/auth/signup/actions";
import { setPresentUserStatus } from "redux/reducer/UserStatus/UserStatusAction";
import Analytics from "analytics/Analytics";
import { ANALYTICS_CONST } from "constants/analytics";
import { VerifyStyled } from "./VerifyStyled";

const Verify = () => {
  const dispatch = useDispatch();
  const { signupDetails } = useSelector((state: RootState) => ({
    signupDetails: state.signup,
  }));

  const { modal, setModal } = useModal();
  const { destination, medium, options, handleResend, handleSubmit } = useVerify();
  const { mParticleLoginIdentity } = useMParticle();
  const { isPageIdleForThirtyMins, setIsPageIdleForThirtyMins, setLastActionTime } = useCheckIsPageIdleForThirtyMins();

  const handleGoToAccountCreated = () => setModal("ACCOUNT_CREATED");

  const handleOpenCancelAccountCreationModal = () => {
    // Added amplitude event "cancel_account_creation_pop_up" when user goes to cancel account creation modal
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.CANCEL_ACCOUNT_CREATION_POP_UP, undefined, false);
    setModal("CANCEL_ACCOUNT_CREATION");
  };

  const mparticleLoginCallback = () => {
    const userIdentities = {
      email: signupDetails?.username,
    };

    mParticleLoginIdentity(userIdentities);
  };

  const handleSubmitExtended = async (code: string) => {
    return await handleSubmit(code, mparticleLoginCallback).then((res) => {
      if (res.success && res.response.authenticationResult) {
        setSessionStorageData("tokens", JSON.stringify(res.response.authenticationResult));
        dispatch(setPresentUserStatus("FIRST_TIME")); // delete after complete integration
        handleGoToAccountCreated();
      }
      return res;
    });
  };

  const handleUpdateActionTime = () => {
    setLastActionTime(new Date().getTime());
  };

  const handleCodeChange = (value: string) => {
    dispatch(setSignupDetails({ ...signupDetails, verifyCode: value }));
    handleUpdateActionTime();
  };

  // If the page is Idle for 30 mins then clear the entered PHONE NUMBER and PASSWORD fields
  useEffect(() => {
    if (isPageIdleForThirtyMins) {
      deleteUser({ email: signupDetails?.email ?? "", phone: signupDetails?.phone ?? "", shouldFinish: false });
      dispatch(setSignupDetails({ ...signupDetails, phone: "", password: "" }));
      setIsPageIdleForThirtyMins(false);
      setModal("SIGNUP");
    }
  }, [isPageIdleForThirtyMins]);

  const BODY = (
    <EnterCode
      codeLength={6}
      target={destination}
      onResend={handleResend}
      onSubmit={handleSubmitExtended}
      onChange={handleCodeChange}
      initialCode={signupDetails?.verifyCode}
      medium={medium}
      options={options}
      hidePhoneNumber
    />
  );

  return (
    <VerifyStyled>
      <Modal isOpen={modal === "VERIFY"} overlayClassName="signupFormOverlay" mobileVariant="fullScren">
        <SignInSignUpCard
          className="verify-modal"
          body={BODY}
          onClose={handleOpenCancelAccountCreationModal}
          isCloseRequired={true}
          isCloseRequiredInMobile={true}
          isBackVisible={true}
          onBack={handleOpenCancelAccountCreationModal}
        />
      </Modal>
    </VerifyStyled>
  );
};

export default Verify;
