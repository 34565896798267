import { SetSignupDetailsAction, SetSignupDetailsPayload, ResetSignupDataAction } from "./types";

export const setSignupDetails = (payload: SetSignupDetailsPayload): SetSignupDetailsAction => ({
  type: "SET_SIGNUP_DETAILS",
  payload,
});

export const resetSignupData = (): ResetSignupDataAction => ({
  type: "RESET",
});
