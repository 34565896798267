import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSignupDetails } from "redux/reducer/auth/signup/actions";
import { ANALYTICS_CONST } from "constants/analytics";
import Analytics from "analytics/Analytics";
import Button from "components/atomic-components/atoms/button/Button";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import PromptModal from "components/atomic-components/molecules/Modals/PromptModal/PromptModal";
import useModal from "hooks/useModal";
import { useCheckIsPageIdleForThirtyMins } from "hooks/useCheckIsIdleForThirtyMins";
import { deleteUser } from "api/users/delete-user";
import { RootState } from "src/types/types";
import { CancelAccountCreationPopupStyled, Body, Footer } from "./CancelAccountCreationPopupStyled";

const CancelAccountCreationPopup = () => {
  const dispatch = useDispatch();
  const { signupDetails } = useSelector((state: RootState) => ({
    signupDetails: state.signup,
  }));

  const { modal, setModal } = useModal();
  const { isPageIdleForThirtyMins, setIsPageIdleForThirtyMins } = useCheckIsPageIdleForThirtyMins();

  const handleYESButtonClick = () => {
    // Added amplitude event "click_yes_Im_sure_cancel_account_creation" when user is sure to cancel registration process.
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.CLICK_YES_IM_SURE_CANCEL_ACCOUNT_CREATION, undefined, false);

    deleteUser({ email: signupDetails?.email ?? "", phone: signupDetails?.phone ?? "", shouldFinish: false }).then(() => {
      dispatch(setSignupDetails({ ...signupDetails, verifyCode: "" }));
      setModal("SIGNUP");
    });
  };

  const handleFinishButton = () => {
    // Added amplitude event "click_finish_account_cancel_account_creation" when user goes back to complete registration
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.CLICK_FINISH_ACCOUNT_AFTER_BACK, undefined, false);

    deleteUser({ email: signupDetails?.email ?? "", phone: signupDetails?.phone ?? "", shouldFinish: true }).then(() => {
      setModal("VERIFY");
    });
  };
  const handleCloseButton = () => {
    // Added amplitude event "close_cancel_account_creation"
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.CLOSE_CANCEL_ACCOUNT_CREATION, undefined, false);

    deleteUser({ email: signupDetails?.email ?? "", phone: signupDetails?.phone ?? "", shouldFinish: true }).then(() => {
      setModal("VERIFY");
    });
  };

  // If the page is Idle for 30 mins then clear the entered PHONE NUMBER and PASSWORD fields
  useEffect(() => {
    if (isPageIdleForThirtyMins) {
      deleteUser({ email: signupDetails?.email ?? "", phone: signupDetails?.phone ?? "", shouldFinish: false });
      dispatch(setSignupDetails({ ...signupDetails, phone: "", password: "" }));
      setIsPageIdleForThirtyMins(false);
      setModal("SIGNUP");
    }
  }, [isPageIdleForThirtyMins]);

  const BODY = (
    <Body>
      <Headings variant="primaryHeading2" fontSize={40} mobileFontSize={24} className="cancelAccountTitle">
        CANCEL ACCOUNT <br /> CREATION?
      </Headings>
      <Paragraph
        variant="paragraphSubHeading1"
        fontSize={18}
        lineHeight={21.6}
        mobileFontSize={14}
        fontWeight={400}
        mobileFontWeight={500}
        className="cancelAccountSubtitle"
      >
        If you leave now, your account will not be created. Are you sure you want to leave?
      </Paragraph>
    </Body>
  );

  const FOOTER = (
    <Footer>
      <Button className="pButton" type="button" variant="outlinedWhite" onClick={handleYESButtonClick}>
        YES, I&#39;M SURE
      </Button>
      <Button className="pButton" type="button" variant="primaryRed" onClick={handleFinishButton}>
        FINISH ACCOUNT
      </Button>
    </Footer>
  );

  return (
    <CancelAccountCreationPopupStyled>
      <PromptModal
        isOpen={modal === "CANCEL_ACCOUNT_CREATION"}
        body={BODY}
        footer={FOOTER}
        onClose={handleCloseButton}
        className="thresholdWrapper"
        isCloseRequired
        isButtonTextVisible
      />
    </CancelAccountCreationPopupStyled>
  );
};

export default CancelAccountCreationPopup;
