import { SignupAction, SignupState } from "./types";

const initialState: SignupState = {
  username: null,
  password: null,
  codeDeliveryDetails: null,
  verifyCode: "",
};

export const signupReducer = (state = initialState, action: SignupAction): SignupState => {
  switch (action.type) {
    case "SET_SIGNUP_DETAILS":
      return { ...state, ...action.payload };

    case "RESET":
      return initialState;

    default:
      return state;
  }
};
