import { useState, ReactNode } from "react";
import { BLUE_CHEVRON_DOWN } from "assets/images";
import { DropdownContainer, TitleWrapper, BulletPoints, Paragraph, Chevron } from "./StyledDropdownNote";

interface DropdownProps {
  title: string;
  bulletPoints: ReactNode[];
}

const DropdownNote = ({ title, bulletPoints }: DropdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDropdown = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <DropdownContainer>
      <TitleWrapper onClick={toggleDropdown}>
        <Paragraph variant="title">{title}</Paragraph>
        <Chevron src={BLUE_CHEVRON_DOWN} alt="chevron icon" height={15} width={15} isExpanded={isExpanded} />
      </TitleWrapper>
      <BulletPoints isVisible={isExpanded}>
        {bulletPoints.map((point, index) => (
          <li key={index}>
            <Paragraph variant="point">{point}</Paragraph>
          </li>
        ))}
      </BulletPoints>
    </DropdownContainer>
  );
};

export default DropdownNote;
