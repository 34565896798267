import styled from "styled-components";

interface CodeInputStyledProps {
  error: boolean;
  valueLength: number;
  isDisabled: boolean;
}

export const CodeInputStyled = styled.span<CodeInputStyledProps>`
  ${(props) =>
    props.error &&
    `
      .codeInputStyle {
        border: 2px solid ${props.theme.color.primary.tongueTorchRed}
      }
    `}
  border: none;
  margin-top: 15px;
  .codeInputStyle {
    color: ${(props) => (props.isDisabled ? props.theme.color.secondary.coolGray4 : props.theme.color.primary.foundersBlue)};
    font-size: 16px !important;
    font-weight: 600;
    padding: 10px;
    width: 29px;
    height: 34px;
    border-radius: 0.25rem;
    font-family: ${(props) => props.theme.font.barlow};
    margin: 0 5px 0 5px;
    transition: transform 0.1s linear;
    background-color: ${(props) => props.theme.color.limitedUse.pureWhite};
    border: ${(props) => (props.isDisabled ? "none" : `2px solid ${props.theme.color.secondary.coolGray1}`)};

    ${(props) =>
      props.error &&
      `
            color: ${props.theme.color.primary.foundersBlue} !important;
            border: 2px solid ${props.theme.color.primary.tongueTorchRed};
          `}
    &::placeholder {
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-size: 20px;
      align-items: center;
      justify-content: center;
    }
    &:focus {
      ${(props) =>
        props.error &&
        `
            border: 2px solid ${props.theme.color.primary.tongueTorchRed};
          `}
      transform: scale(1);
      border: 2px solid ${(props) => props.theme.color.primary.foundersBlue} !important;
      outline: none;
      @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
        font-size: 16px;
        line-height: 28.8px;
      }
      &::placeholder {
        color: transparent;
      }
    }

    @media only screen and (min-width: ${(props) => props.theme.size.xs}) {
      min-width: 34px;
      height: 40px;
      padding: 0;
    }

    @media only screen and (min-width: ${(props) => props.theme.size.md}) {
      min-width: 48px;
      height: 60px;
      padding: 10px;
    }
    (max-width: ${(props) => props.theme.size.lg}) {
      margin: 0 4px 0 4px;
      padding: 2px;
      width: 24px !important;
    }
  }
`;

export const Separator = styled.span`
  padding: 0 5px;
  display: none;
`;
