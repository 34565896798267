import styled from "styled-components";
import Image from "next/image";

export const DropdownContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.color?.secondary.coolGray4};
  border-bottom: 1px solid ${(props) => props.theme.color?.secondary.coolGray4};
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 15px;
  width: 70%;
  max-width: 448px;
  cursor: pointer;

  ${(props) => props.theme.responsive?.max.md} {
    width: 294px;
  }

  .linkStyles {
    ${(props) => props.theme.responsive?.min.lg} {
      pointer-events: none;
      cursor: default;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  user-select: none;
`;

export const BulletPoints = styled.ul<{ isVisible: boolean }>`
  padding-left: 14px;
  padding-bottom: ${({ isVisible }) => (isVisible ? "20px" : "0")};
  display: block;
  max-height: ${({ isVisible }) => (isVisible ? "200px" : "0")};
  overflow: hidden;
  transition: 0.2s ease;
  user-select: none;
  cursor: default;

  li {
    font-size: 13px;
    margin-bottom: 4px;

    a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      color: ${(props) => props.theme.color?.primary.foundersBlue};
    }
  }
`;

export const Paragraph = styled.p<{ variant: "title" | "point" }>`
  font-family: ${(props) => props.theme.font?.barlow};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  margin: 0;

  ${(props) =>
    props.variant === "title" &&
    `
    font-size: 20px;
    font-weight: 700;
    margin-right: 5px;

    ${props.theme.responsive?.max.md} {
      font-size: 16px;
    }
  `}

  ${(props) =>
    props.variant === "point" &&
    `
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  `}
`;

export const Chevron = styled(Image)<{ isExpanded: boolean }>`
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) => (isExpanded ? "rotate(180deg)" : "rotate(0deg)")};
  margin-top: 1px;
`;

export const EnterCodeStyled = styled.div<{ isError: boolean; isShowCMSErrorMessage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  margin: 0 auto;
  margin-top: 20px;
`;
