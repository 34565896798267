import dayjs from "dayjs";
import {
  setIsPromoCodeApplied,
  setPromoInput,
  setRewardToBasketAction,
  setSelectedRewardAction,
  setShowPromoCodeInput,
  setUserSelectedCard,
} from "redux/reducer/rewards/actions";
import { getCityBasedStoreList, getStoreList } from "redux/reducer/Store/actions";
import { setUserTokens, setVehicleList } from "redux/reducer/user/actions";
import { resetBasket } from "redux/reducer/basket/actions";
import { resetPayment } from "redux/reducer/freedompay/actions";
import { setAppleIFrameURL } from "redux/reducer/applepay/actions";
import { BASKET, STORES } from "api/endpoints";
import { setWebInitialValue } from "src/apis/generateTimeBasedTokenForWeb";
import { ANALYTICS_CONST } from "constants/analytics";
import store from "redux/store";
import { sortList } from "utils/objectSorting";
import {
  AddressForDelivery,
  cartItemDetails,
  DeliveryAddress,
  GiftCard,
  Product,
  ResponseStoreData,
  Store,
  StoreTimings,
  UserAddress,
  basketProducts,
  cartDiscounts,
  RecentOrderResponse,
} from "src/types/types";
import { REORDER_CLICKED, SET_REORDER_ITEMID } from "redux/reducer/ReOrder/ReOrderConst";
import { getUserLocation } from "utils/getLocation";
import { reorderRecentOrders, setNewBasketFromFavReorder } from "redux/reducer/ReOrder/ReOrderAction";
import Analytics from "analytics/Analytics";
import {
  deleteLocalStorageData,
  deleteSessionStorageData,
  getLocalStorageData,
  getSessionStorageData,
  removeCookieByName,
  setLocalStorageData,
} from "utils/storage";
import { mparticleLogoutIdentity } from "utils/mparticle/mparticleHelper";
import { setPresentUserStatus } from "redux/reducer/UserStatus/UserStatusAction";
import { resetCurbsideOrderDetails, resetPlaceOrderValues, resetRecentOrders } from "redux/reducer/PlaceOrder/PlaceOrderActions";
import { resetGiftCardLaterAction, setIsGiftCardsAvailable, setShowUseGiftCard } from "redux/reducer/GiftCards/GiftCardsAction";
import { resetSignupData } from "redux/reducer/auth/signup/actions";
import { apiCall } from "src/apis/api";

export const navigateToMenuPage = (address: UserAddress, storeList: Array<Store>, city: string, type?: string, id?: number) => {
  if (address.latitude !== 0 && address.longitude !== 0) {
    store.dispatch(
      // @ts-ignore
      getStoreList({
        lat: address.latitude,
        lng: address.longitude,
        startdate: dayjs().format("YYYY-MM-DD"),
      })
    );
  } else if (city) {
    store.dispatch(
      // @ts-ignore
      getCityBasedStoreList({
        cityName: city,
        startdate: dayjs().format("YYYY-MM-DD"),
      })
    );
  }
  if (storeList.length > 0 && Object.keys(address).length !== 0) {
    const list = sortList(storeList, "distance", "asc");
    let first;
    if (id) {
      first = storeList.find((sId: Store) => sId?.id === id);
    } else {
      first = list.slice(0, 1).shift();
    }

    return first;
  }
};

export const beautifyPrice = (price: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });
  return formatter.format(price);
};

export const badgeChecker = (itemBadge: string | undefined) => {
  switch (itemBadge) {
    case "NEW":
      return "new-badge";
    case "LIMITED":
      return "limited-badge";
    case "UNAVAILABLE":
      return "unavailable-badge";
    default:
      return "no-badge";
  }
};

export const apostrophizeName = (name?: string) => {
  const apostropheEnding = name?.toLowerCase().endsWith("s") ? "'" : "'s";
  return `${name}${apostropheEnding}`;
};

export const setActiveCategoryName = (categoryName: string | undefined, router: any, actions: any, selectedStoreId?: number) => {
  if (categoryName) {
    if (selectedStoreId) {
      router.push(
        {
          pathname: "/menu",
          query: { storeId: selectedStoreId },
        },
        `/menu#${categoryName}`
      );
    } else {
      router.push(`/menu/#category-${categoryName}`);
    }

    store.dispatch(actions?.isRedirectedFromHome(true));
    store.dispatch(actions?.setSelectedCategoryName(categoryName));
  }
};

export const getStoreListWithZip = (value: string) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${STORES}/search?address=${value}&radius=30&limit=10&startdate=${dayjs().format("YYYY-MM-DD")}`,
  }).then((response) => response.response);
};

export const beautifyPickupType = (pickupType: string) =>
  ({
    instore: "INSTORE",
    drivethru: "DRIVE-THRU",
    curbside: "CURBSIDE",
  }[pickupType]);

export const createBasketPromiseValue = (storeId: number, accessToken: string) => {
  return apiCall({
    requestType: "POST",
    apiEndPoint: `${BASKET}/create`,
    apiPostData: {
      storeId: storeId,
      accessToken: accessToken,
    },
  }).then((response) => {
    return response.response;
  });
};

export const calculatePercentage = (amount: number, percentage: number) => {
  return ((amount * percentage) / 100).toFixed(3).toString();
};

export const escapeCategoryName = (category?: string) =>
  category
    ? category
        .toLowerCase()
        .replace(/[^a-zA-Z ]/g, "")
        .replace(/ +/g, "-")
    : "";

export const getSum = (a: number = 0, b: number = 0) => {
  const costA = a || 0;
  const costB = b || 0;
  return costA + costB;
};
// Have to use any here, since consumer of function could pass any type of array
// To the function
export const getRandomValueFromArray = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)];

export const isUrlExternal = (url: string) => url.indexOf(":") > -1 || url.indexOf("//") > -1;

export const getPriceCalorieCalculation = (selectedOptions: any) => {
  let totalBaseCalorie = 0;
  let totalMaxCalorie = 0;
  let total = 0;

  if (selectedOptions?.length > 1) {
    total = selectedOptions?.reduce((a: Product, b: Product) => getSum(a.cost, b.cost));
    totalBaseCalorie = selectedOptions?.reduce((a: Product, b: Product) => getSum(parseInt(a.baseCalories!), parseInt(b.baseCalories!)));
    totalMaxCalorie = selectedOptions?.reduce((a: Product, b: Product) => getSum(parseInt(a.maxCalories!), parseInt(b.maxCalories!)));
  } else if (selectedOptions?.length === 1) {
    total = selectedOptions[0]?.cost;
    totalBaseCalorie = parseInt(selectedOptions[0].baseCalories || 0);
    totalMaxCalorie = parseInt(selectedOptions[0].maxCalories || 0);
  }

  return {
    totalBaseCalorie,
    totalMaxCalorie,
    total,
  };
};

export const beautifyLegalHeading = (value: string) =>
  value
    .replace(/^# \d]{1,2}.\s/, "")
    .trim()
    .replace(/[^a-zA-Z\s]/g, "")
    .replace(/[\s]+/g, "-")
    .toLowerCase();

export const reorderItems = (
  basket: cartItemDetails,
  faveReorder: boolean,
  accessToken: string,
  orderId: string | number,
  router: any,
  selectedStoreId: number,
  corporateStoreId: string | number,
  orderDetails: RecentOrderResponse | null,
  handoffMode?: string,
  idToken?: string,
  address?: DeliveryAddress
) => {
  // trigger Amplitude and mParticle click_reorder event on reorder button click
  const items = orderDetails?.products.reduce((carry, p: { name: string }) => (carry ? carry + ", " + p.name : p.name), "")?.toUpperCase();
  Analytics.getInstance().logEvent(ANALYTICS_CONST.ORDER.CLICK_REORDER, {
    ecommerce: {
      items,
    },
  });

  const cartProductLength = basket?.products?.length;
  const newHandoffMode = handoffMode === "instore" ? "pickup" : handoffMode;
  if (cartProductLength > 0) {
    store.dispatch({ type: SET_REORDER_ITEMID, payload: orderId });
    store.dispatch({ type: REORDER_CLICKED, payload: true });
  } else {
    if (selectedStoreId) {
      if (faveReorder) {
        // order from fav orders
        store.dispatch(
          // @ts-ignore
          setNewBasketFromFavReorder({
            favoriteId: orderId,
            storeId: selectedStoreId,
            accessToken: accessToken,
            handoffMode: newHandoffMode,
            address: address,
            idToken,
          })
        );
      } else {
        // order from recent orders
        orderDetails &&
          store.dispatch(
            // @ts-ignore
            reorderRecentOrders(
              {
                id: orderId?.toString(),
                accessToken: accessToken,
                storeId: selectedStoreId,
                handoffMode: newHandoffMode,
                address: address,
                idToken,
                orderDetails,
              },
              router
            )
          );
      }
    } else {
      getUserLocation();
      router.push("/locations");
    }
  }
};

export const getOldestGiftCard = (cards: Array<GiftCard>) => {
  const sorter = (firstGiftCard: GiftCard, secondGiftCard: GiftCard) => {
    return new Date(firstGiftCard.createdAt).getTime() - new Date(secondGiftCard.createdAt).getTime();
  };

  if (cards) {
    cards.sort(sorter);
  }

  return cards?.length > 0 ? cards[0] : null;
};

// Funtion returns username with first letter capitalized
export const getModifiedUsername = (firstName: string | undefined, lastName: string | undefined) => {
  const firstNameModified = firstName ? firstName.charAt(0).toUpperCase() + firstName.slice(1) : "";
  const lastNameModified = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : "";
  return firstNameModified + " " + lastNameModified;
};

export const isRewardDiscountInBasket = (basket: cartItemDetails) => {
  const isDiscount = basket?.discounts?.find((e: cartDiscounts) => e.type === "reward");
  if (isDiscount) {
    return true;
  }
  return false;
};

export const displayTime = (item: any) => {
  return new Date(item).toLocaleTimeString().replace(/:\d+ /, "");
};

export const displayDate = (item: any) => {
  return dayjs(item).format("MM/DD/YY");
};

export const checkHistoryLength = () => {
  // history.length is used to check whether user landed on page direct or from other page.Based on the length we are redirecting user.
  // If length is 2, User landed directly on this page, if greater than 2, landed from other page of application
  if (typeof history !== "undefined" && history.length === 2) {
    return true;
  } else {
    return false;
  }
};

export const doesStoreHaveTiming = (storeTiming: StoreTimings) => {
  if (storeTiming && Object.keys(storeTiming).length > 0) {
    return true;
  } else {
    return false;
  }
};

export const cleanPhoneNumberFormat = (value: string) => {
  return value.replace(/[() -]/g, "");
};

export const getStoreForDelivery = async (data: AddressForDelivery) => {
  return apiCall({
    requestType: "POST",
    apiEndPoint: `${STORES}/deliveringto`,
    apiPostData: data,
  }) as Promise<ResponseStoreData>;
};

export const getBasketProductsCounts = (products: basketProducts[]) => {
  let count = 0;
  if (products && products.length > 0) {
    products.forEach((product) => {
      count += product.quantity;
    });
  }
  return count;
};

export const handleUserLogout = () => {
  // logout event on from mparticle current account
  mparticleLogoutIdentity();

  // While logging out if there is guestUserDetails key in the session storage delete it
  const lastSavedGuestUserDetails = getSessionStorageData("guestUserDetails");
  if (lastSavedGuestUserDetails) {
    deleteSessionStorageData("guestUserDetails");
  }

  deleteSessionStorageData("tokens");
  deleteLocalStorageData("tokens");
  deleteLocalStorageData("tokenExpirationTime");
  deleteLocalStorageData("userDetails");
  removeCookieByName("basketId");
  // Reset Initial Value of the Expiry Time
  setWebInitialValue();
  store.dispatch(setPresentUserStatus("GUEST"));
  store.dispatch(setUserTokens(null));
  store.dispatch(resetBasket());
  // @ts-ignore
  store.dispatch(resetPlaceOrderValues());
  // @ts-ignore
  store.dispatch(resetGiftCardLaterAction());
  // @ts-ignore
  store.dispatch(resetPayment());
  store.dispatch(resetRecentOrders());
  store.dispatch(setAppleIFrameURL(""));
  store.dispatch(setSelectedRewardAction(null));
  store.dispatch(setRewardToBasketAction(null));
  store.dispatch(setIsPromoCodeApplied(false));
  store.dispatch(setPromoInput(""));
  store.dispatch(setShowPromoCodeInput(false));
  store.dispatch(setUserSelectedCard());
  store.dispatch(setIsGiftCardsAvailable(false));
  store.dispatch(setShowUseGiftCard(true));
  // @ts-ignore
  store.dispatch(resetCurbsideOrderDetails());
  store.dispatch(setVehicleList([]));

  // clear the signup details
  store.dispatch(resetSignupData());
};

export const extractDay = () => {
  const day = dayjs().day();
  switch (day) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
  }
};

export const matchDayOfWeek = (value: string) => {
  return value && value.substring(0, 3) === extractDay();
};

export const updateStoreDetatilsInWebStorage = (pickupOrderType: string) => {
  const localData = getLocalStorageData("store");
  const sessionData = getSessionStorageData("store");

  const storedData = localData ?? sessionData;
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    parsedData.pickupOrderType = pickupOrderType;
    setLocalStorageData("store", JSON.stringify(parsedData));
  }
};

export const getTokenFromWebStorage = () => {
  const localData = getLocalStorageData("tokens");
  const sessionData = getSessionStorageData("tokens");

  if (localData) return localData;
  else return sessionData;
};

export const findNextActiveIndex = (currentIndex: number, arrayLength: number = 0) => {
  if (currentIndex <= arrayLength - 2) return currentIndex + 1;
  return 0;
};

export const debounce = <F extends (...args: any[]) => void>(func: F, wait: number = 1000): ((...args: Parameters<F>) => void) => {
  let timer: any;
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, wait);
  };
};

export const beautifyPriceReorder = (price: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let formattedPrice = formatter.format(price);

  // Check if the integer part is a single digit (0-9) and prepend a zero if needed
  if (price < 10) {
    formattedPrice = "0" + formattedPrice;
  }

  return formattedPrice;
};
